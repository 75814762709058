import { KeyValPair }     from '@models/common'
import { FormStatusEnum } from '@services/model/form/form.model'

export interface EAPartATemplateField {
	field: string
	displayName: string
	fieldType?: 'number' | 'select' | 'string' | 'displayNumber'
	options?: KeyValPair[]
}

export function EAPartATemplates(excelType: string,
                                 getOptionsByKey: (key: string) => KeyValPair[],
                                 isHighSpeedRoadContract: boolean,
                                 formStatus: string): EAPartATemplateField[] {
	switch (excelType) {
		case 'Maintenance of Road Marking':
		case 'Maintain Road Markings and Road Studs':
		case 'Maintenance of Road Marking and Road Studs':
		case 'Conduct Inspections for and Maintenance of Road Marking': {
			return [
				{
					field      : 'auditSequence',
					displayName: 'Audit Sequence'
				},
				{
					field      : 'neaSampleNo',
					displayName: 'Sampling No.'
				},
				{
					field      : 'neaAuditCode',
					displayName: 'Sample Selected'
				},
				{
					field      : 'neaLocation',
					displayName: 'Road Name'
				},
				{
					field      : 'neaShapeLength',
					displayName: 'Total Lane Length Sampled (m)',
					fieldType  : 'displayNumber'
				}
			]
		}
		case 'Maintain Road Drainage System':
		case 'Maintenance of Road Drainage System': {
			return [
				{
					field      : 'auditSequence',
					displayName: 'Audit Sequence'
				},
				{
					field      : 'neaSampleNo',
					displayName: 'Sampling No.'
				},
				{
					field      : 'neaAuditCode',
					displayName: 'Sample Selected'
				},
				{
					field      : 'neaLocation',
					displayName: 'Road Name'
				},
				{
					field      : 'neaRoadTypeId',
					displayName: 'Road Type',
					fieldType  : 'select',
					options    : getOptionsByKey('Form EA - Road Type')
				},
				{
					field      : 'neaShapeLength',
					displayName: 'Total Road Area Sampled (m²)',
					fieldType  : 'displayNumber'
				}
			]
		}
		case 'Conduct Routine Inspections and Undertake Associated General Road Maintenance Works':
		case 'Routine Inspections and Associated General Road Maintenance Works': {
			const neaParts: Array<EAPartATemplateField> = [
				{
					field      : 'auditSequence',
					displayName: 'Audit Sequence'
				},
				{
					field      : 'neaSampleNo',
					displayName: 'Sampling No.'
				}
			]

			if (!isHighSpeedRoadContract)
				neaParts.push({
					              field      : 'neaAuditCode',
					              displayName: 'Sample Selected'
				              },
				              {
					              field      : 'neaLocation',
					              displayName: 'Road Name'
				              },
				              {
					              field      : 'neaRoadTypeId',
					              displayName: 'Road Type',
					              fieldType  : 'select',
					              options    : getOptionsByKey('Form EA - Road Type')
				              })

			return neaParts
		}
		case 'Conducting Routine Inspections and Undertake Associated General Road Maintenance Works': {
			const neaParts: Array<EAPartATemplateField> = [{
				field      : 'auditDate',
				displayName: 'Audit Date'
			}]

			return isHighSpeedRoadContract ? neaParts : []
		}
		case 'Conduct Detailed Inspections of Roads and Undertake Associated General Road Maintenance Works':
		case 'Detailed Inspections and Associated General Road Maintenance Works': {
			return [
				{
					field      : 'auditSequence',
					displayName: 'Audit Sequence'
				},
				{
					field      : 'neaSampleNo',
					displayName: 'Sampling No.'
				},
				{
					field      : 'neaAuditCode',
					displayName: 'Sample Selected'
				},
				{
					field      : 'neaLocation',
					displayName: 'Road Name'
				},
				{
					field      : 'neaRoadTypeId',
					displayName: 'Road Type',
					fieldType  : 'select',
					options    : getOptionsByKey('Form EA - Road Type')
				},
				{
					field      : 'neaShapeArea',
					displayName: 'Total Road Area Sampled (m²)',
					fieldType  : 'displayNumber'
				}
			]
		}
		case 'Conduct Inspection of Stepping ≧5mm and ≦10mm on Paver Footways':
		case 'Stepping Inspection on Paver Footways': {
			return [
				{
					field      : 'auditSequence',
					displayName: 'Audit Sequence'
				},
				{
					field      : 'neaSampleNo',
					displayName: 'Sampling No.'
				},
				{
					field      : 'neaAuditCode',
					displayName: 'Sample Selected'
				},
				{
					field      : 'neaLocation',
					displayName: 'Road Name'
				},
				{
					field      : 'neaShapeArea',
					displayName: 'Total Road Sections Sampled (m²)',
					fieldType  : 'displayNumber'
				}
			]
		}
		case 'Conduct Inspections of Highway Structures and Undertake Structural Maintenance':
		case 'Conduct Inspections on Highway Structures and Undertake Structural Maintenance': {
			return [
				{
					field      : 'auditSequence',
					displayName: 'Audit Sequence'
				},
				{
					field      : 'neaSampleNo',
					displayName: 'Sampling No.'
				},
				{
					field      : 'neaAuditCode',
					displayName: 'Sample Selected'
				},
				{
					field      : 'neaLocation',
					displayName: 'Location'
				},
				{
					field      : 'neaStructureNo',
					displayName: 'Structure No.'
				},
				{
					field      : 'planAreaOfStructure',
					displayName: 'Plan Area of the Structure (m²)',
					fieldType  : formStatus === FormStatusEnum.FORM_EA_AGREE_PRELIM_RESULT ? 'displayNumber' : 'number'
				}
			]
		}
		case 'Conduct Routine Maintenance Inspection For and Maintain Roadside Slopes':
		case 'Conduct Routine Maintenance Inspection for and Maintain Roadside Slopes': {
			return [
				{
					field      : 'auditSequence',
					displayName: 'Audit Sequence'
				},
				{
					field      : 'neaSampleNo',
					displayName: 'Sampling No.'
				},
				{
					field      : 'neaAuditCode',
					displayName: 'Sample Selected'
				},
				{
					field      : 'neaLocation',
					displayName: 'Location'
				},
				{
					field      : 'neaGeoRegistrationNo',
					displayName: 'Slope No.'
				},
				{
					field      : 'neaHydRegistrationNo',
					displayName: 'HyD Reference No.'
				}
			]
		}
		case 'Conduct Inspections of Tunnel Segments and Undertake Structural Maintenance':
		case 'Conducting Inspection of Tunnel Segements and Undertaking Structural Maintenance': {
			return [
				{
					field      : 'auditSequence',
					displayName: 'Audit Sequence'
				},
				{
					field      : 'neaSampleNo',
					displayName: 'Sampling No.'
				},
				{
					field      : 'neaAuditCode',
					displayName: 'Sample Selected'
				},
				{
					field      : 'neaLocation',
					displayName: 'Tunnel Name (Bound)'
				},
				{
					field      : 'neaLength',
					displayName: 'Total Tunnel Portions length Sampled (m)',
					fieldType  : 'displayNumber'
				}
			]
		}
		case 'Maintain Vegetation on Vegetated Highway Registered Slopes and Designated Planted Areas':
		case 'Vegetation Maintenance on Roadsides Slopes and Designated Planted Areas': {
			return [
				{
					field      : 'auditSequence',
					displayName: 'Audit Sequence'
				},
				{
					field      : 'neaSampleNo',
					displayName: 'Sampling No.'
				},
				{
					field      : 'neaAuditCode',
					displayName: 'Sample Selected'
				},
				{
					field      : 'neaLocation',
					displayName: 'Location'
				},
				{
					field      : 'neaSlopeNo',
					displayName: 'Slope No.'
				},
				{
					field      : 'neaVegetationId',
					displayName: 'Vegetation ID'
				}
			]
		}
		default: {
			return []
		}
	}
}