import { useContext }                      from 'react'
import { useNavigate }                     from 'react-router-dom'
import { GlobalContext }                   from '@providers/globalStore'
import { RightsCategory }                  from '@services/model/form/form.model'
import { Box, useTheme }                   from '@mui/system'
import { Grid, Typography, useMediaQuery } from '@mui/material'
import SettingsIcon                        from '@mui/icons-material/Settings'
import { StyledCard }                      from './styled'

interface Props {
	name: string
	onClickAction?: () => any
}

const SettingsItem = (
	{
		name,
		onClickAction
	}: Props
) => (
	<StyledCard onClick={ onClickAction }>
		{ name }
	</StyledCard>
)

const Settings = () => {
	const theme                    = useTheme()
	const matchUpMd                = useMediaQuery(theme.breakpoints.up('md'))
	const { hasRightByCatAndCode } = useContext(GlobalContext)
	const navigate                 = useNavigate()

	return (
		<Box sx={ {
			padding      : matchUpMd ? '40px' : '10px',
			gap          : '12px',
			display      : 'flex',
			flexDirection: 'column'
		} }>
			<Box display='flex'
			     gap='20px'
			     alignItems='center'
			     sx={ { width: '100%' } }>
				<SettingsIcon sx={ { color: '#707070', fontSize: 40 } } />
				<Typography sx={ { fontSize: '2.125rem', fontWeight: 'bold', color: '#707070' } }>
					Settings
				</Typography>
			</Box>
			<Grid container spacing={ 2 }>
				<Grid item xs={ 6 } md={ 3 }>
					<SettingsItem name={ 'Contracts' } onClickAction={ () => navigate('/settings/contracts/list') } />
				</Grid>
				<Grid item xs={ 6 } md={ 3 }>
					<SettingsItem name={ 'Workgroups' } onClickAction={ () => navigate('/settings/workgroups/list') } />
				</Grid>
				{
					hasRightByCatAndCode(RightsCategory.FORM_USER, ['C', 'R', 'U', 'D'], true) &&
                    <>
                      <Grid item xs={ 6 } md={ 3 }>
                        <SettingsItem name={ 'Users' } onClickAction={ () => navigate('/admin') } />
                      </Grid>
                      <Grid item xs={ 6 } md={ 3 }>
                        <SettingsItem name={ 'Duty Effective Time' }
                                      onClickAction={ () => navigate('/admin/duty/all-record') } />
                      </Grid>
                    </>
				}
				<Grid item xs={ 6 } md={ 3 }>
					<SettingsItem name={ 'Works Order/IOW' }
					              onClickAction={ () => navigate('/settings/iow/workorders') } />
				</Grid>
				<Grid item xs={6} md={3}>
            <SettingsItem name={'Teams'} onClickAction={() => navigate('/settings/teams/list')}/>
        </Grid>
        <Grid item xs={6} md={3}>
            <SettingsItem name={'Districts'} onClickAction={() => navigate('/settings/districts/list')}/>
        </Grid>
			</Grid>
		</Box>
	)
}

export default Settings