import { Fragment, useContext, useEffect, useState, useRef }     from 'react'
import { useLocation, useNavigate, useParams }                   from 'react-router-dom'
import { useForm, useWatch }                                     from 'react-hook-form'
import { GlobalContext }                                         from '@providers/globalStore'
import { FormPartComponents }                                    from '@models/common'
import { useTranslation }                                        from 'react-i18next'
import useQuery                                                  from '@hooks/useQuery'
import { QueryStepParser }                                       from '@utils/queryStepParser'
import { FormContext }                                           from '@providers/formStateProvider'
import useFormSwitcher                                           from '@hooks/useFormSwitcher'
import { NavigateTo }                                            from '@utils/navigate'
import { useSnackbar }                                           from 'notistack'
import { DevTool }                                               from '@hookform/devtools'
import { CdrOptions, FormSDModel, InitSDForm, SDGeneralOptions } from '@services/model/form/form.SD.model'
import FormSDService                                             from '@services/formService/form.SD.service'
import FormService                                               from '@services/form.service'
import SDPartA                                                   from './part-a'
import SDPartB                                                   from './part-b'
import SDPartC                                                   from './part-c'
import SDPartD                                                   from './part-d'
import SDPartE                                                   from './part-e'
import SDPartF                                                   from './part-f'
import SDPartG                                                   from './part-g'
import SDPartH                                                   from './part-h'
import SDPartI                                                   from './part-i'
import useAPIFetch                                               from '@hooks/useAPIFetch'
import { FormStatusEnum }                                        from '@services/model/form/form.model'
import { yupResolver }                                           from '@hookform/resolvers/yup'
import * as yup                                                  from 'yup'
import ValidationToast                                           from '@components/form/ValidationToast'
import getFormValidationSchema                                   from '@utils/formValidationSchema'
import useLargeMap                                               from '@hooks/useLargeMap'
import { find, get }                                             from 'lodash'
import useLocalStorage                                           from '@hooks/useLocalStorage'
import { DocumentExporter } from '@utils/documentExporter'
import useGeneralOptions from '@hooks/useGeneralOptions_to_be_used'

const SDForm = () => {
  const { state: globalState, userInfo, dispatch: globalAction } = useContext(GlobalContext)
  const { state: formState, dispatch: formStateAction } = useContext(FormContext)

  const navigate = useNavigate()
  const { t } = useTranslation()

  // extracting id from params
  let { id: formId } = useParams<string>()

  // extracting step from query
  const query = useQuery()
  const step = QueryStepParser(query.get('step'))
  const { getOptionsByKey } = useGeneralOptions()

  const validationSchemaConditions = [
    {
      formStatusList: [FormStatusEnum.FORM_SD_DRAFT, FormStatusEnum.FORM_SD_REJECTED],
      validationSchema: yup.object().shape({
        baseForm: yup.object().shape({
          contractNoId: yup
            .number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .moreThan(0, t('Contract No. is required'))
            .required(),
          teamId: yup.array().nullable().min(1).required(t('Team is required')),
          districtId: yup.array().nullable().min(1).required(t('District is required')),
          workOrderId: yup
            .number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .moreThan(0)
            .required(t('Works Order No. is required')),
        }),
        formDate: yup.date().required(t('Date is required')),
        cdrRef: yup.boolean().required(t("Contractor's Daily Report Ref. is required")),
        cdrId: yup.number().when('cdrRef', {
          is: true,
          then: yup.number().required(t("Contractor's Daily Report No. is required")),
          otherwise: yup.number().nullable(),
        }),
        itemNo: yup.number().when('cdrRef', {
          is: true,
          then: yup.number().required(t('Item No. is required')),
          otherwise: yup.number().nullable(),
        }),
        worksStatusId: yup.number().required(t('Works Status is required')),
        workCompletionId: yup
          .number()
          .required(t('Estimated Percentage of Works Completion is required')),
        siteProgressForLabour: yup
          .array()
          .of(
            yup.object().shape({
              tradeId: yup.number().required(t('Trade in Site Progress Labour table is required')),
              company: yup
                .string()
                .required(t('Company in Site Progress Labour table is required')),
              noOfWorker: yup
                .number()
                .required(t('No. of worker in Site Progress Labour table is required')),
              workingHourFrom: yup
                .string()
                .required(t('Working Hours in Site Progress Labour table is required')),
              workingHourTo: yup
                .string()
                .required(t('Working Hours in Site Progress Labour table is required')),
              resourceTypeId: yup
                .number()
                .required(t('Resource Type in Site Progress Labour table is required')),
            }),
          )
          .nullable(),
        siteProgressForPlant: yup
          .array()
          .of(
            yup.object().shape({
              plantType: yup
                .string()
                .required(t('Plant type in Site Progress Plant table is required')),
              plantId: yup
                .string()
                .required(t('Plant Id in Site Progress Plant table is required')),
              company: yup.string().required(t('Company in Site Progress Plant table is required')),
              noOfPlant: yup
                .number()
                .required(t('No. of Plant in Site Progress Plant table is required')),
              noOfIdlePlant: yup
                .number()
                .required(t('No. of idle Plant in Site Progress Plant table is required')),
              idleCodeId: yup
                .number()
                .required(t('Idle Code in Site Progress Plant table is required')),
              workingHourFrom: yup
                .string()
                .required(t('Working Hours in Site Progress Plant table is required')),
              workingHourTo: yup
                .string()
                .required(t('Working Hours in Site Progress Plant table is required')),
              resourceTypeId: yup
                .number()
                .required(t('Resource Type in Site Progress Labour table is required')),
            }),
          )
          .nullable(),
      }),
    },
    {
      formStatusList: [
        FormStatusEnum.FORM_SD_REVIEW,
        FormStatusEnum.FORM_SD_CONTRACTOR_REVIEW,
        FormStatusEnum.FORM_SD_CONTRACTOR_REJECTED,
        FormStatusEnum.FORM_SD_APPROVED,
        FormStatusEnum.FORM_SD_COUNTERSIGNED,
      ],
      validationSchema: yup.object(),
    },
  ]

  const { control, watch, setValue, getValues, reset, trigger } = useForm<FormSDModel>({
    defaultValues: { ...InitSDForm },
    resolver: (data, context, options) => {
      const validatorSchema = getFormValidationSchema(
        data.baseForm.formStatus,
        validationSchemaConditions,
      )
      return yupResolver(validatorSchema)(data, context, options)
    },
    mode: 'all',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
  })

  const [editPartB, setEditPartB] = useState<boolean>(false)
  const { setRequest, isLoading } = useAPIFetch()
  const { setRequest: setOptionRequest, isLoading: isLoadingOption } = useAPIFetch()

  const { enqueueSnackbar } = useSnackbar()
  const isMounted = useRef(false)
  const [formStatus, formPermission] = getValues(['baseForm.formStatus', 'baseForm.formPermission'])
  console.debug('[getValues]', getValues())
  const [sdGeneralOptions, setSdGeneralOptions] = useState<SDGeneralOptions>(
    globalState.formOptionsList?.find(({ key }) => key === 'SD')?.value ?? {},
  )
  const readOnly =
    formStatus !== FormStatusEnum.FORM_SD_DRAFT && formStatus !== FormStatusEnum.FORM_SD_REJECTED
  // formStatus !== FormStatusEnum.FORM_SD_CONTRACTOR_REJECTED
  const [cdrOptions, setCdrOptions] = useState<CdrOptions | undefined>()
  const [storageContractId, setStorageContractId] = useLocalStorage<string | undefined>(
    'contract',
    undefined,
  )
  const { state } = useLocation()
  const { cloneData } = state || {}

  const reload = async () => {
    setRequest({
      callback: async () => {
        if (formId) {
          await FormSDService.GetSdForm(formId)
            .then(async (f) => {
              if (f) reset(f)
              if (f.baseForm.formStatus === FormStatusEnum.FORM_SD_DRAFT) {
                setEditPartB(true)
              }

              if (f.baseForm.contractNoId && f.baseForm.contractNoId !== globalState.contractNoId) {
                globalAction({
                  type: 'selectContract',
                  contractNoId: f.baseForm.contractNoId,
                })

                globalAction({
                  type: 'selectWorkGroup',
                  workGroupId: get(
                    find(globalState.contractList, { id: f.baseForm.contractNoId }),
                    'workGroups.0.id',
                    0,
                  ),
                })
              }

              globalAction({
                type: 'changeFormStatus',
                formStatus: f.baseForm.formStatusName,
              })
            })
            .catch((err) => {
              enqueueSnackbar(err.response.data.message, {
                variant: 'error',
                autoHideDuration: null,
              })
              NavigateTo(navigate, '/site-diary/all-record')
            })
        }

        isMounted.current = true
      },
    })
  }

  const cloneDataToNewSD = () => {
    reset(cloneData)
    globalAction({
                   type      : 'changeFormStatus',
                   formStatus: 'Draft',
                 })
    setEditPartB(true)
  }

  useEffect(() => {
    if (!isMounted.current) {
      if (formState.form && formState.formType === 'SD') {
        formStateAction({ type: 'pop', targetFormType: 'SD' })

        reset({ ...formState.form })
      } else if (formId) {
        reload()
      } else {
        if (cloneData) {
          cloneDataToNewSD()
        } else {
          setValue('baseForm.contractNoId', storageContractId ? parseInt(storageContractId) : 0)
          setValue('baseForm.districtId', userInfo.districts)
          setValue('baseForm.teamId', userInfo.teams)
        }
      }

      globalAction({
        type: 'changeFormStatus',
        formStatus: getValues('baseForm.formStatusName'),
      })
    }

    return () => {
      // formStateAction({ type: 'clear' })
      globalAction({
        type: 'changeFormStatus',
        formStatus: undefined,
      })
    }
  }, [formId, formStateAction, globalAction])

  const handleGetWorksOrderNo = async () => {
    console.log('handleWorksOrderNo')

    if (
      formStatus === FormStatusEnum.FORM_SD_DRAFT ||
      formStatus === FormStatusEnum.FORM_SD_REJECTED
    ) {
      setRequest({
        callback: async () => {
          let workOrderId = getValues('baseForm.workOrderId') || 0
          const list = await FormSDService.GetCdrOptions({
            contractNoId: getValues('baseForm.contractNoId'),
            formDate: getValues('formDate') || new Date(),
            WorkOrderId: workOrderId,
          }).catch(() => {
            enqueueSnackbar(t('Error in fetching Work order data, please try again'), {
              variant: 'error',
            })
          })

          if (list) {
            setCdrOptions(list)
          }
        },
      })
    }
  }

  const handleExportDocument = async () => {
    setRequest({
      callback: async () => {
        await DocumentExporter(
          '/templates/SD_template.docx',
          getValues('sdNo') ?? 'SD',
          getValues(),
          {},
          {
            sdGeneralOptions: sdGeneralOptions,
            globalState: globalState,
            resourceType: getOptionsByKey('Form SD - Labour'),
            constructionPlant: getOptionsByKey('Form SD/SCR - Construction Plant'),
            wipType: getOptionsByKey('Form SD/SCR - WIP Type Sub Option'),
            workSCompletionList: getOptionsByKey('Form SD - Estimated Percentage of Works Completion'),
            essentialOperationList: getOptionsByKey('Form SD - Essential Operations Involved'),
            inSituTestList: getOptionsByKey('Form SD - In-situ Test'),
            inSituSampleList: getOptionsByKey('Form SD - In-situ Sample'),
            sitePhotoCategory: getOptionsByKey('Form SD - Site Photo Category'),
            dayNightWorks: getOptionsByKey('Form SD - Day Night Works')
          }
        )
      },
    })
  }

  const [disableNav, setDisableNav] = useState<boolean>(true) //for part A onNext disable
  const partA: FormPartComponents = {
    partTitle: t('General Information'),
    component: (
      <SDPartA
        control={control}
        options={sdGeneralOptions}
        setEditPartB={setEditPartB}
        watch={watch}
        userInfo={userInfo}
        useWatch={useWatch}
        setValue={setValue}
        getValues={getValues}
        readOnly={readOnly}
        handleGetWorksOrderNo={handleGetWorksOrderNo}
        cdrOptions={cdrOptions}
        setDisableNav={setDisableNav}
        globalState={globalState}
      />
    ),
    onNextDisabled: disableNav,
  }

  const partB: FormPartComponents = {
    partTitle: t("Site Progress - Contractor's Reported Works"),
    component: (
      <SDPartB
        editPartB={editPartB}
        options={sdGeneralOptions}
        control={control}
        watch={watch}
        readOnly={readOnly}
        globalState={globalState}
      />
    ),
  }

  const partC: FormPartComponents = {
    partTitle: t('Site Progress - Progress of Works'),
    component: (
      <SDPartC control={control} readOnly={readOnly} useWatch={useWatch} setValue={setValue} />
    ),
  }

  const partD: FormPartComponents = {
    partTitle: t("Site Progress - Contractor's Resource on Site"),
    component: (
      <SDPartD
        control={control}
        options={sdGeneralOptions}
        readOnly={readOnly}
        getValues={getValues}
      />
    ),
  }

  const [LargeMap, exportCanvas] = useLargeMap({
    value: getValues('sdPhotos'),
    uneditable: !(
      formStatus === FormStatusEnum.FORM_SCR_DRAFT ||
      formStatus === FormStatusEnum.FORM_SCR_REJECTED
    ),
  })

  const saveTargetMap = async () => {
    let targetBase64 = await exportCanvas()
    setValue('summaryMapBase64', targetBase64)
  }

  const partE: FormPartComponents = {
    partTitle: t('Site Photos'),
    component: (
      <SDPartE
        control={control}
        dispatch={formStateAction}
        getValues={getValues}
        id={formId}
        readOnly={readOnly}
        options={sdGeneralOptions}
        LargeMap={LargeMap}
      />
    ),
    onNext: () => saveTargetMap(),
  }

  const partF: FormPartComponents = {
    partTitle: t('Checklist'),
    component: (
      <SDPartF
        control={control}
        useWatch={useWatch}
        readOnly={readOnly}
        options={sdGeneralOptions}
      />
    ),
  }

  const partG: FormPartComponents = {
    partTitle: t('Other Information'),
    component: <SDPartG control={control} formStatus={formStatus} readOnly={readOnly} />,
  }

  const partH: FormPartComponents = {
    partTitle: t('Temporary Hidden Works Record'),
    component: (
      <SDPartH control={control} getValues={getValues} readOnly={readOnly} useWatch={useWatch} />
    ),
  }

  const partI: FormPartComponents = {
    partTitle: t('Submission Confirmation'),
    component: (
      <SDPartI
        control={control}
        getValues={getValues}
        handleOnComplete={() => handleOnComplete()}
        globalState={globalState}
        formPermission={formPermission}
      />
    ),
  }

  const handleOnSubmit = async (
    event: any,
    isReject: boolean,
    notifyList?: string[],
    signatureBase64?: string,
    submissionComment?: string,
  ) => {
    if (!(await trigger())) return
    setValue('signatureBase64', signatureBase64)
    var ff = getValues()
    ff.signatureBase64 = signatureBase64
    ff.notifyUserList = notifyList
    ff.submissionComment = submissionComment
    // ff.essentialOperation = JSON.stringify(ff.essentialOperation);
    // ff.inSituTest =  JSON.stringify(ff.inSituTest);
    // ff.inSituSample =  JSON.stringify(ff.inSituSample);
    if (isReject) {
      setRequest({
        callback: async () => {
          await FormSDService.RejectSdForm(ff).then((resp) => {
            enqueueSnackbar(t('Record Submitted'), { variant: 'success' })
            NavigateTo(navigate, '/site-diary/all-record')
          })
        },
      })
    } else {
      setRequest({
        callback: async () => {
          console.log('form', ff)
          await FormSDService.ApproveSdForm(ff).then((resp) => {
            enqueueSnackbar(t('Record Submitted'), { variant: 'success' })
            NavigateTo(navigate, '/site-diary/all-record')
          })
        },
      })
    }
  }

  const handleOnSave = async () => {
    setRequest({
      callback: async () => {
        await FormSDService.SaveSdForm(getValues()).then((resp) => {
          setValue('baseForm.formId', resp.formId)
          setValue('baseForm.formStatus', resp.formStatus)
          NavigateTo(navigate, '/site-diary/:id', {
            id: resp.formId,
          })
          formId = resp.formId
          reload()
          enqueueSnackbar(t('Record Saved'), { variant: 'success' })
        })
      },
    })
  }

  const handleOnDelete = async () => {
    setRequest({
      callback: async () => {
        await FormSDService.DeleteSdForm(getValues())
          .then(() => {
            enqueueSnackbar(t('Record Archived'), { variant: 'success' })
            NavigateTo(navigate, '/site-diary/all-record')
          })
          .catch((err) => {
            enqueueSnackbar(err.response.data.message, { variant: 'error' })
          })
      },
    })
  }

  const onLoadNotifyList = async (isReject: boolean) => {
    var defaultNotifyList = await FormService.GetNotifyList(
      'SD',
      getValues('baseForm.formId'),
      isReject,
      getValues('baseForm.districtId'),
      getValues('baseForm.teamId'),
    )
    return defaultNotifyList
  }

  const [FormSwitcher, handleOnComplete] = useFormSwitcher({
    title: t('SITE DIARY'),
    components: [partA, partB, partC, partD, partE, partF, partG, partH, partI],
    formOnLoadNotifyList: onLoadNotifyList,
    formOnLoadCommentList: async () => {
      return await FormService.GetCommentList(getValues('baseForm.formId'))
    },
    formOnSubmitComment: async (comment) => {
      await FormService.SubmitComment(getValues('baseForm.formId'), comment)
    },
    formOnSubmit: handleOnSubmit,
    formOnDelete: handleOnDelete,
    formOnSave: handleOnSave,
    onExportDocument: handleExportDocument,
    startStep: step,
    isLoading: isLoading || isLoadingOption,
    disableSave: !formPermission?.canUpdate, // can edit this form
    disableDelete: !formPermission?.canDelete, // can delete this form
    disableComment: getValues('baseForm.formId') ? false : true,
    approveRequired: formPermission?.workflowRequired, // can go to next stage
    endOfFlow: formPermission?.endOfFlow,
    isMounted: isMounted.current,
  })

  return (
    <Fragment>
      <FormSwitcher />
      <DevTool control={control} />
      <ValidationToast control={control} />
    </Fragment>
  )
}

export default SDForm
