import { KeyValPair }                                                                           from '@models/common'
import { FormDNModel }                                                                          from '@services/model/form/form.DN.model'
import { LocationModel }                                                                        from '../location.model'
import { ImageModel }                                                                           from '../image.model'
import { FormModel, InitFormModel, FormStatusEnum, InitFormStatusHistory, DashboardFormStatus } from './form.model'
import { BaseFilterOptions, BaseListResponse }                                                  from '../base.model'

export interface FormNFModel {
	baseForm: FormModel
	from?: string
	contractNoId?: string
	nfNo?: string
	scopeOfWorksId?: number
	defectCategoryId?: number
	defectOptionId?: number
	rectificationAt?: Date
	timeLimitId?: number
	location?: LocationModel
	roadName?: string
	locationRemark?: string
	dateOfCheck?: Date
	dueDate?: Date
	followUpAction?: string
	followUpActionTo?: string
	coveringWorkOrder?: boolean
	remarks?: string
	images: ImageModel[]
	rectifications?: NfRectificationModel[]
	eiNo?: string
	formDn: FormDNModel[]
	noDNform?: number[]

	caseSourceId?: number
	caseSource?: string
	iccNo?: string

	signatureBase64?: string
	notifyUserList?: string[]
	submissionComment?: string
	district?: number
	team?: number
	locationMapBase64?: string
}

export interface NfRectificationModel {
	rectificationVersion: number | null
	rectificationDate: Date | null
	otherInfo?: string
	rectificationImages?: ImageModel[]
}

export interface NfListResponse extends BaseListResponse {
	list: FormNFListModel[]
}

export interface NfFilterOptions extends BaseFilterOptions {
	parentFormIdList: KeyValPair[]
	workOrderIdList: number[]
	defectTypes: Array<KeyValPair>
}

export const InitNFForm: FormNFModel = {
	images     : [],
	formDn     : [],
	baseForm   : {
		...InitFormModel,

		formStatus         : FormStatusEnum.FORM_NF_DRAFT,
		formStatusHistories: [{ ...InitFormStatusHistory, actionName: 'Draft' }]
	},
	dateOfCheck: new Date()
}

export interface FormNFListModel {
	formId: number
	color: string
	formStatus: string
	formStatusShortName: string
	formStatusColor: string

	reportNo: string
	parentReportNo: string
	contractNoId: number
	teamId: number[]
	districtId: number[]
	roadName: string
	defectType?: string
	inspectionDate: Date
	dueDate: Date
	submissionDate?: Date
	issueDate?: Date
	completionDate?: Date
	nonComplianceId: number[]
	caseSourceID?: number

	submittedBy: string
	approvedBy: string
	updatedAt: Date
}

export interface DashboardDefect {
	category: string
	total: number
}

export interface NfDashboard {
	formStatus: Array<DashboardFormStatus>
	defect: Array<DashboardDefect>
}

export interface NfRectificationPhotoImageModel {
	nfPhotoImageId: number
	uuid: string
	status: boolean
	description?: string
	filename: string
	size: number
	ext: string
	uuidRegular?: string
	uuidThumbnail?: string
	bytes: number[]
}

export interface FormNfRectificationModel {
	baseForm: FormModel
	signatureBase64?: string
	NfRectificationDto: NfRectificationModel[]
	formDN: FormDNModel[]
}

export type NFGeneralOptions = {
	defectCategory: {
		id: number
		name: string
		defectOptions: {
			id: number
			defectCode: string
			description: string
			rmiCode: string
			timeLimits: {
				id: number
				timeLimit: string
			}[]
		}[]
	}[]
	scopeOfWorks: {
		id: number
		name: string
	}[]
	caseSource: {
		id: number
		name: string
	}[]
}

export interface NfDashboardReq {
	teamId: Array<number>
	startDate: Date,
	endDate: Date
}