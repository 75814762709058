import SearchPanel from '@components/searchPanel'
import { Grid, IconButton, Tooltip } from '@mui/material'
import * as common from '@common/common'
import { GlobalContext } from '@providers/globalStore'
import { InitialSearchPanelState, SearchPanelContext } from '@providers/SearchPanelProvider'
import SearchPanelReducer, { SearchPanelState } from '@reducers/searchPanelReducer'
import moment from 'moment'
import { Fragment, useContext, useReducer, useRef, useState } from 'react'
import { NavigateFunction } from 'react-router-dom'
import useTableView from '@hooks/useTableView'
import { useTranslation } from 'react-i18next'
import FormStatusLabel from '@components/FormStatusLabel'
import GetValueWithKey from '@utils/getValueWithKey'

import FormDNService from '@services/formService/form.DN.service'

import { NavigateTo } from '@utils/navigate'
import { KeyValPair } from '@models/common'
import {
  DnListResponse,
  FormDnListModel,
  DnFilterOptions,
} from '@services/model/form/form.DN.model'
import { RightsCategory } from '@services/model/form/form.model'
import { exportToCSV } from '@utils/exportExcel'
import ExportIcon from '@components/icon/export'

const AllRecord = (props: { showMyRecord?: boolean }) => {
  const refMounted = useRef(false)
  const { state: globalState, userInfo } = useContext(GlobalContext)
  const [state, dispatch] = useReducer<React.Reducer<SearchPanelState, any>>(
    SearchPanelReducer,
    InitialSearchPanelState,
  )

  const { t } = useTranslation()
  const [extraListInfo, setExtraListInfo] = useState<DnFilterOptions>({
    submittedByList: [],
    approvedByList: [],
    formIdList: [],
    parentFormIdList: [],
    workOrderIdList: [],
  })

  const getExtraListInfo = async () => {
    let extraInfo = await FormDNService.GetDnFilterOption(props.showMyRecord)
    setExtraListInfo(extraInfo)
    refMounted.current = true
  }

  const getTableFullList = async () => {
    let fullList = await FormDNService.GetDNList(
      {
        pagination: { skipPagination: true },
      },
      '',
      props.showMyRecord || false,
    )
    let list: any[] = fullList.list.map((x) => {
      console.log(
        ' globalState.generalOptionDNGroupList?.find(( option ) => {option.id === x.nonComplianceId})',
        globalState.generalOptionDNGroupList?.find((option) => {
          option.id === x.nonComplianceId
        }),
      )
      return {
        'General Status': x.formStatusShortName,
        'Submission Status': x.formStatus,
        'NF Form': x.parentReportNo,
        'Form ID': x.reportNo,
        'DN Type': GetValueWithKey(
          x.nonComplianceId,
          globalState.generalOptionDNGroupList?.map((x) => {
            return { key: x.id, value: x.dnRefNo }
          }) ?? [],
        ),
        'Inspection Date': x.inspectionDate
          ? moment(x.inspectionDate).format(moment.defaultFormat)
          : 'N/A',
        'NC Period':
          (x.startAt ? moment(x.startAt).format() : 'N/A') +
          ' - ' +
          (x.endAt ? moment(x.endAt).format() : 'N/A'),
        'Submitted By': GetValueWithKey(x.submittedBy, globalState.userMetaList ?? []),
        'Approved By': GetValueWithKey(x.approvedBy, globalState.userMetaList ?? []),
        'Last update': x.updatedAt ? moment(x.updatedAt).format(moment.defaultFormat) : 'N/A',
      }
    })
    console.log('list', list)
    return list ?? []
  }

  const [TableView, reload] = useTableView<FormDnListModel>({
    headers: [
      {
        key: 'formStatusShortName',
        desc: t('General Status', { ns: common.i18nFormNS }),
        renderCell: (record: FormDnListModel) => (
          <Fragment>
            <FormStatusLabel
              label={record.formStatusShortName}
              color={record.formStatusColor || ''}
            />
          </Fragment>
        ),
      },
      {
        key: 'formStatus',
        desc: t('Submission Status', { ns: common.i18nFormNS }),
        renderCell: (record: FormDnListModel) => <Fragment>{record.formStatus}</Fragment>,
      },
      {
        key: 'parentFormNo',
        desc: t('Parent Form', { ns: common.i18nFormNS }),
        renderCell: (record: FormDnListModel) => <Fragment>{record.parentReportNo}</Fragment>,
      },
      {
        key: 'formId',
        desc: t('Form ID', { ns: common.i18nFormNS }),
        renderCell: (record: FormDnListModel) => <Fragment>{record.reportNo}</Fragment>,
      },
      {
        key: 'nonComplianceId',
        desc: t('DN Type', { ns: common.i18nFormNS }),
        renderCell: (record: FormDnListModel) => (
          <Fragment>
            {GetValueWithKey(
              record.nonComplianceId,
              globalState.generalOptionDNGroupList?.map((x) => {
                return {
                  key: x.id,
                  value: x.dnRefNo,
                }
              }) ?? [],
            )}
          </Fragment>
        ),
      },
      {
        key: 'inspectionDate',
        desc: t('Inspection Date', { ns: common.i18nFormNS }),
        renderCell: (record: FormDnListModel) => (
          <Fragment>
            {record.inspectionDate
              ? moment(record.inspectionDate).format(moment.defaultFormat)
              : 'N/A'}
          </Fragment>
        ),
      },
      {
        key: 'ncPeriodStart',
        desc: t('NC Period', { ns: common.i18nFormNS }),
        renderCell: (record: FormDnListModel) => (
          <Fragment>
            {(record.startAt ? moment(record.startAt).format() : 'N/A') +
              ' - ' +
              (record.endAt ? moment(record.endAt).format() : 'N/A')}
          </Fragment>
        ),
      },
      {
        key: 'submittedBy',
        desc: t('Submitted By', { ns: common.i18nFormNS }),
        renderCell: (record: FormDnListModel) => (
          <Fragment>{GetValueWithKey(record.submittedBy, globalState.userMetaList ?? [])}</Fragment>
        ),
        mobileHidden: true,
      },
      {
        key: 'approvedBy',
        desc: t('Approved By', { ns: common.i18nFormNS }),
        renderCell: (record: FormDnListModel) => (
          <Fragment>{GetValueWithKey(record.approvedBy, globalState.userMetaList ?? [])}</Fragment>
        ),
        mobileHidden: true,
      },
      {
        key: 'updatedAt',
        desc: t('Last Update', { ns: common.i18nFormNS }),
        renderCell: (record: FormDnListModel) => (
          <Fragment>
            {record.updatedAt ? moment(record.updatedAt).format(moment.defaultFormat) : 'N/A'}
          </Fragment>
        ),
        mobileHidden: true,
      },
    ],
    onRowClick: function (navigate: NavigateFunction, record: FormDnListModel) {
      NavigateTo(navigate, '/default-notice-for-non-compliance/:id', { id: record.formId })
    },
    mountedRef: refMounted,
    reloadCallback: async (pagination: object, sorting: object | undefined, cancelToken: any) => {
      if (refMounted.current) {
        sessionStorage.setItem('DN', JSON.stringify(state.filterResult))
        let resp = await FormDNService.GetDNList(
          {
            ...state.filterResult,
            sorting: sorting,
            pagination: pagination,
          },
          cancelToken,
          props.showMyRecord || false,
        )
        return resp
      }
    },
  })

  return (
    <Grid component="main" container padding={1}>
      <SearchPanelContext.Provider value={{ state, dispatch }}>
        <SearchPanel
          dispatch={dispatch}
          addUrl="/booking/new"
          onSearch={reload}
          onInitReload={reload}
          onToggleFilterDrawer={getExtraListInfo}
          mountedRef={refMounted}
          excludeStatus={true}
          criteria={[
            {
              key: 'formStatus',
              desc: 'Submission Status',
              type: 'NumberSelectionFilter',
              multiple: true,
              valueList:
                globalState.formStatusList
                  ?.filter((x) => x.actionCode.includes(RightsCategory.FORM_DN))
                  .map((x) => {
                    return {
                      key: x.id,
                      value: x.actionName,
                    }
                  }) || [],
              defaultValue:
                sessionStorage?.DN !== 'undefined' && sessionStorage?.DN !== undefined
                  ? JSON.parse(sessionStorage?.DN)?.formStatus?.value
                  : [],
            },
            {
              key: 'parentFormNo',
              desc: 'Parent Form',
              type: 'StringSelectionFilter',
              multiple: true,
              valueList: extraListInfo.parentFormIdList,
              defaultValue:
                sessionStorage?.DN !== 'undefined' && sessionStorage?.DN !== undefined
                  ? JSON.parse(sessionStorage?.DN)?.parentFormNo?.value
                  : [],
            },
            {
              key: 'formId',
              desc: 'Form ID',
              type: 'StringSelectionFilter',
              multiple: true,
              valueList: extraListInfo.formIdList,
              defaultValue:
                sessionStorage?.DN !== 'undefined' && sessionStorage?.DN !== undefined
                  ? JSON.parse(sessionStorage?.DN)?.formId?.value
                  : [],
            },
            {
              key: 'nonComplianceId',
              desc: 'DN Type',
              type: 'NumberSelectionFilter',
              multiple: true,
              valueList: globalState.generalOptionDNGroupList?.map((x) => {
                return {
                  key: x.id,
                  value: x.dnRefNo,
                }
              }),
              defaultValue:
                sessionStorage?.DN !== 'undefined' && sessionStorage?.DN !== undefined
                  ? JSON.parse(sessionStorage?.DN)?.nonComplianceId?.value
                  : [],
            },
            {
              key: 'inspectionDate',
              desc: 'Inspection Date',
              type: 'DateFilter',
              defaultOperator:
                sessionStorage?.DN !== 'undefined' && sessionStorage?.DN !== undefined
                  ? JSON.parse(sessionStorage?.DN)?.inspectionDate?.operator
                  : 'in',
              defaultFrom:
                sessionStorage?.DN !== 'undefined' && sessionStorage?.DN !== undefined
                  ? JSON.parse(sessionStorage?.DN)?.inspectionDate?.value?.min
                  : '',
              defaultTo:
                sessionStorage?.DN !== 'undefined' && sessionStorage?.DN !== undefined
                  ? JSON.parse(sessionStorage?.DN)?.inspectionDate?.value?.max
                  : '',
            },
            {
              key: 'ncPeriodStart',
              desc: 'NC Start',
              type: 'DateFilter',
              defaultOperator:
                sessionStorage?.DN !== 'undefined' && sessionStorage?.DN !== undefined
                  ? JSON.parse(sessionStorage?.DN)?.ncPeriodStart?.operator
                  : 'in',
              defaultFrom:
                sessionStorage?.DN !== 'undefined' && sessionStorage?.DN !== undefined
                  ? JSON.parse(sessionStorage?.DN)?.ncPeriodStart?.value?.min
                  : '',
              defaultTo:
                sessionStorage?.DN !== 'undefined' && sessionStorage?.DN !== undefined
                  ? JSON.parse(sessionStorage?.DN)?.ncPeriodStart?.value?.max
                  : '',
            },
            {
              key: 'ncPeriodEnd',
              desc: 'NC End',
              type: 'DateFilter',
              defaultOperator:
                sessionStorage?.DN !== 'undefined' && sessionStorage?.DN !== undefined
                  ? JSON.parse(sessionStorage?.DN)?.ncPeriodEnd?.operator
                  : 'in',
              defaultFrom:
                sessionStorage?.DN !== 'undefined' && sessionStorage?.DN !== undefined
                  ? JSON.parse(sessionStorage?.DN)?.ncPeriodEnd?.value?.min
                  : '',
              defaultTo:
                sessionStorage?.DN !== 'undefined' && sessionStorage?.DN !== undefined
                  ? JSON.parse(sessionStorage?.DN)?.ncPeriodEnd?.value?.max
                  : '',
            },
            {
              key: 'submittedBy',
              desc: 'Submitted By',
              type: 'StringSelectionFilter',
              multiple: true,
              valueList: globalState.userMetaList?.filter((x) =>
                extraListInfo.submittedByList?.includes(x.key),
              ),
              defaultValue:
                sessionStorage?.DN !== 'undefined' && sessionStorage?.DN !== undefined
                  ? JSON.parse(sessionStorage?.DN)?.submittedBy?.value
                  : [],
            },
            {
              key: 'approvedBy',
              desc: 'Approved By',
              type: 'StringSelectionFilter',
              multiple: true,
              valueList: globalState.userMetaList?.filter((x) =>
                extraListInfo.approvedByList?.includes(x.key),
              ),
              defaultValue:
                sessionStorage?.DN !== 'undefined' && sessionStorage?.DN !== undefined
                  ? JSON.parse(sessionStorage?.DN)?.approvedBy?.value
                  : [],
            },
            {
              key: 'updatedAt',
              desc: 'Last Update',
              type: 'DateFilter',
              defaultOperator:
                sessionStorage?.DN !== 'undefined' && sessionStorage?.DN !== undefined
                  ? JSON.parse(sessionStorage?.DN)?.updatedAt?.operator
                  : 'in',
              defaultFrom:
                sessionStorage?.DN !== 'undefined' && sessionStorage?.DN !== undefined
                  ? JSON.parse(sessionStorage?.DN)?.updatedAt?.value?.min
                  : '',
              defaultTo:
                sessionStorage?.DN !== 'undefined' && sessionStorage?.DN !== undefined
                  ? JSON.parse(sessionStorage?.DN)?.updatedAt?.value?.max
                  : '',
            },
          ]}
          extraButtons={
            <Tooltip title="Export">
              <IconButton
                sx={{
                  backgroundColor: globalState.headerColor,
                  borderRadius: '10%',
                  width: '40px',
                  height: '40px',
                  '&:hover': { background: globalState.headerColor },
                }}
                onClick={async () => await exportToCSV(await getTableFullList(), 'DN-all-record')}>
                <ExportIcon />
              </IconButton>
            </Tooltip>
          }
        />
        <TableView />
      </SearchPanelContext.Provider>
    </Grid>
  )
}

export default AllRecord
