import { useContext, useEffect, useRef, useState }                     from 'react'
import { useForm }                                                     from 'react-hook-form'
import { useTranslation }                                              from 'react-i18next'
import { useNavigate, useParams }                                      from 'react-router-dom'
import uuid                                                            from 'react-uuid'
import * as yup                                                        from 'yup'
import { find, get }                                                   from 'lodash'
import { useSnackbar }                                                 from 'notistack'
import DialogButton                                                    from '@components/button/DialogButton'
import FormContainer                                                   from '@components/form/container'
import FormSignatureHistories                                          from '@components/form/signatureHistories'
import ValidationToast                                                 from '@components/form/ValidationToast'
import { DevTool }                                                     from '@hookform/devtools'
import { yupResolver }                                                 from '@hookform/resolvers/yup'
import useAPIFetch                                                     from '@hooks/useAPIFetch'
import useFormSwitcher                                                 from '@hooks/useFormSwitcher'
import useGeneralOptions                                               from '@hooks/useGeneralOptions_to_be_used'
import useLocalStorage                                                 from '@hooks/useLocalStorage'
import useQuery                                                        from '@hooks/useQuery'
import { FormPartComponents }                                          from '@models/common'
import { FormContext }                                                 from '@providers/formStateProvider'
import { GlobalContext }                                               from '@providers/globalStore'
import FormService                                                     from '@services/form.service'
import FormNeaService                                                  from '@services/formService/form.NEA.service'
import { FormStatusEnum }                                              from '@services/model/form/form.model'
import { FormNeaModel, InitNEAForm as InitNeaForm, NeaGeneralOptions } from '@services/model/form/form.NEA.model'
import getFormValidationSchema                                         from '@utils/formValidationSchema'
import { NavigateTo }                                                  from '@utils/navigate'
import { QueryStepParser }                                             from '@utils/queryStepParser'
import PartA                                                           from './part-a'
import PartB                                                           from './part-b'
import PartC                                                           from './part-c'
import { DocumentExporter } from '@utils/documentExporter'

const UUID = uuid()
export default function NEAForm() {
  const { state: globalState, userInfo, dispatch: globalAction } = useContext(GlobalContext)

  const { state, dispatch: formStateAction } = useContext(FormContext)
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const [storageContractId, setStorageContractId] = useLocalStorage<string | undefined>(
    'contract',
    undefined,
  )
  // extracting id from params
  let { id: formId } = useParams<string>()

  const { getOptionsByKey } = useGeneralOptions()

  // extracting step from query
  const query = useQuery()
  const step = QueryStepParser(query.get('step'))
  const validationSchemaConditions = [
    {
      formStatusList: [FormStatusEnum.FORM_NEA_DRAFT],
      validationSchema: yup.object().shape({
        typeOfMmWorks: yup.number().required(t('Type of M&M Works is required')),
        witnessedBy: yup.string().required(t('Witnessed by is required')),
        monthYearOfPerformanceStandard: yup
          .date()
          .required(t('Month/Year of Performance Standard is required')),
        baseForm: yup.object().shape({
          contractNoId: yup
            .number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .moreThan(0)
            .required(t('Contract No. is required')),
          teamId: yup.array().required().min(1).max(1),
          districtId: yup.array().required().min(1).max(1),
        }),
        neaExcels: yup.array().when(['typeOfMmWorks', 'baseForm'], {
          is: (typeOfMmWorks, baseForm) =>
            typeOfMmWorks ===
              find(getOptionsByKey('Form EA - Category'), {
                value:
                  'Conducting Routine Inspections and Undertake Associated General Road Maintenance Works',
              })?.key &&
            find(globalState.contractList, { id: baseForm.contractNoId })?.highSpeedRoad,
          then: yup.array().nullable(),
          otherwise: yup
            .array()
            .nullable()
            .required(t('Excels is required'))
            .min(1, t('Excels is required')),
        }),
      }),
    },
    {
      formStatusList: [
        FormStatusEnum.FORM_NEA_SAMPLE_SELECTED,
        FormStatusEnum.FORM_NEA_ENDORSEMENT_REJECTED,
      ],
      validationSchema: yup.object().shape({
        dateOfAudit: yup.date().required(t('Date of Audit is required')),
        auditingOfficer: yup.string().required(t('Assign Checking Officer is required')),
        checkingOfficer: yup.string().required(t('Assign Checking Officer is required')),
        baseForm: yup.object().shape({
          workOrderId: yup
            .number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .moreThan(0)
            .required(t('Works Order No. is required')),
          teamId: yup.array().required().min(1).max(1),
          districtId: yup.array().required().min(1).max(1),
        }),
      }),
    },
    {
      formStatusList: [FormStatusEnum.FORM_NEA_REVIEW, FormStatusEnum.FORM_NEA_ENDORSED],
      validationSchema: yup.object(),
    },
  ]

    const { control, watch, setValue, getValues, reset, trigger } = useForm<FormNeaModel>({
                                                                                              defaultValues : { ...InitNeaForm },
                                                                                              resolver      : (data, context, options) => {
                                                                                                  const validatorSchema = getFormValidationSchema(data.baseForm.formStatus,
                                                                                                                                                  validationSchemaConditions)
                                                                                                  return yupResolver(validatorSchema)(data, context, options)
                                                                                              },
                                                                                              mode          : 'all',
                                                                                              reValidateMode: 'onChange',
                                                                                              criteriaMode  : 'all'
                                                                                          })

  // watching form status, signature change
    const formPermission                            = getValues('baseForm.formPermission')
    const [neaGeneralOptions, setNeaGeneralOptions] = useState<NeaGeneralOptions>(globalState.formOptionsList?.find(({ key }) => key === 'NEA')?.value ?? {})

    const isMounted                                                    = useRef(false)
    const { setRequest, isLoading }                                    = useAPIFetch()
    const { setRequest: setOptionRequest, isLoading: isLoadingOption } = useAPIFetch()

  useEffect(() => {
    if (formId) {
      reload()
    } else {
      setValue('baseForm.districtId', userInfo.districts)
      setValue('baseForm.contractNoId', storageContractId ? parseInt(storageContractId) : 0)
      setValue('baseForm.teamId', userInfo.teams)
    }

    globalAction({
      type: 'changeFormStatus',
      formStatus: getValues('baseForm.formStatusName'),
    })

    // clear form stack
    return () => {
      formStateAction({ type: 'clear' })
      globalAction({
        type: 'changeFormStatus',
        formStatus: undefined,
      })
    }
  }, [formId, formStateAction, globalAction])

  const reload = async () => {
    setRequest({
      callback: async () => {
        if (formId) {
          await FormNeaService.GetNeaForm(formId)
            .then(async (f) => {
              if (f) reset(f)

              if (f.baseForm.contractNoId && f.baseForm.contractNoId !== globalState.contractNoId) {
                globalAction({
                  type: 'selectContract',
                  contractNoId: f.baseForm.contractNoId,
                })

                globalAction({
                  type: 'selectWorkGroup',
                  workGroupId: get(
                    find(globalState.contractList, { id: f.baseForm.contractNoId }),
                    'workGroups.0.id',
                    0,
                  ),
                })
              }

              globalAction({
                type: 'changeFormStatus',
                formStatus: f.baseForm.formStatusName,
              })
            })
            .catch((err) => {
              enqueueSnackbar(err.response.data.message, {
                variant: 'error',
                autoHideDuration: null,
              })
              NavigateTo(navigate, '/notification-of-engineer-audit/all-record')
            })
        }

        isMounted.current = true
      },
    })
  }

  const [formStatus, teamId, districtId] = watch([
    'baseForm.formStatus',
    'baseForm.teamId',
    'baseForm.districtId',
  ])

  const reloadNeaGeneralOptions = async (team?: number, district?: number) => {
    if (team !== undefined && district !== undefined) {
      setOptionRequest({
        callback: async () => {
          await FormNeaService.GetNEAOptions(team, district).then(async (list) => {
            if (list) setNeaGeneralOptions(list)
          })
        },
      })
    }
  }

  useEffect(() => {
    reloadNeaGeneralOptions()
  }, [])

  useEffect(() => {
    if (teamId !== null && districtId !== null && teamId.length === 1 && districtId.length === 1) {
      reloadNeaGeneralOptions(teamId[0], districtId[0])
    }
  }, [teamId, districtId])

  const handleOnSubmit = async (
    event: any,
    isReject: boolean,
    notifyList?: string[],
    signatureBase64?: string,
    submissionComment?: string,
  ) => {
    // console.log("validation", validationSchemaFormStatus)
    if (!(await trigger())) return

    handleMeetupLocation()
    setValue('signatureBase64', signatureBase64)

    var ff = getValues()
    ff.signatureBase64 = signatureBase64
    ff.notifyUserList = notifyList
    ff.submissionComment = submissionComment

    if (isReject) {
      setRequest({
        callback: async () => {
          await FormNeaService.RejectNeaForm(ff).then((resp) => {
            enqueueSnackbar(t('Record Submitted'), { variant: 'success' })
            NavigateTo(navigate, '/notification-of-engineer-audit/all-record')
          })
        },
      })
    } else {
      setRequest({
        callback: async () => {
          await FormNeaService.ApproveNeaForm(ff).then((resp) => {
            enqueueSnackbar(t('Record Submitted'), { variant: 'success' })
            NavigateTo(navigate, '/notification-of-engineer-audit/all-record')
          })
        },
      })
    }
  }

  const handleMeetupLocation = () => {
    //set meetup location value
    const formStatus = getValues('baseForm.formStatus')
    if (
      formStatus === FormStatusEnum.FORM_NEA_SAMPLE_SELECTED ||
      formStatus === FormStatusEnum.FORM_NEA_ENDORSEMENT_REJECTED
    ) {
      const districtId = getValues('baseForm.districtId')
      if (districtId) {
        const meetupLocationList =
          neaGeneralOptions?.meetUpLocations?.map((x) => {
            return {
              key: x.id,
              value: x.meetUpLocation,
            }
          }) || []
        if (meetupLocationList.length === 1) {
          setValue('meetupLocationId', meetupLocationList[0].key)
        }
      }
    }
  }

  const handleOnSave = async () =>
      setRequest({
                   callback: async () => {
                     await FormNeaService.SaveNeaForm(getValues())
                                         .then(resp => {
                                           setValue('baseForm.formId', resp.formId)
                                           setValue('baseForm.formStatus', resp.formStatus)
                                           NavigateTo(navigate, '/notification-of-engineer-audit/:id', { id: resp.formId })

                                           formId = resp.formId
                                           reload()

                                           enqueueSnackbar(t('Record Saved'), { variant: 'success' })
                                         })
                   }
                 })

  const handleChangeOfficer = async () =>
      setRequest({
                   callback: async () => {
                     await FormNeaService.ChangeOfficer(getValues())
                                         .then(resp => {
                                           enqueueSnackbar(t('Record Saved'), { variant: 'success' })
                                           NavigateTo(navigate, '/notification-of-engineer-audit/all-record')
                                         })
                   }
                 })

  const handleOnDelete = async () =>
      setRequest({
                   callback: async () => {
                     await FormNeaService.DeleteNeaForm(getValues())
                                         .then(() => {
                                           enqueueSnackbar(t('Record Archived'), { variant: 'success' })
                                           NavigateTo(navigate, '/notification-of-engineer-audit/all-record')
                                         })
                                         .catch(err => {
                                           enqueueSnackbar(err.response.data.message,
                                                           { variant: 'error', autoHideDuration: null })
                                         })
                   }
                 })

  //#region form parts components
  const [iowNameList, setIowNameList] = useState([])

  const NotifyList = async () => {
    let iowList             = []
    //setIowNameList([])
    const defaultNotifyList = await FormService.GetNotifyList('NEA',
                                                              getValues('baseForm.formId'),
                                                              false,
                                                              getValues('baseForm.districtId'),
                                                              getValues('baseForm.teamId'))

    const defaultNotifyUserList =
              globalState.userMetaList?.filter((x) => {
                if (defaultNotifyList?.users?.includes(x.key)) {
                  iowList = iowList.concat(x.key)
                  return true
                }
                return false
              }) || []

    if (iowList.length > 0) {
      setIowNameList(iowList)
      setValue('notifyUserList', iowList)
    }
    // return iowList
  }

  const handleExportDocument = async () => {
    if(formId){
      const data = await FormNeaService.GetFullNeaForm(formId)
      setRequest({
        callback: async () => {
          await DocumentExporter(
            '/templates/NEA_template.docx',
            getValues('neaNotiticationNo') ?? 'NEA',
            data,
            {},
            {
              neaGeneralOptions: neaGeneralOptions,
              globalState: globalState,
              roadType: getOptionsByKey('Form EA - Road Type'),
            }
          )
        },
      })
    }
  }

  useEffect(() => {
    NotifyList()
  }, [teamId, districtId])

  //#region part a
  const NEAPartA = (): FormPartComponents => {
    return {
      partTitle: t('Upload Sample Pool'),
      component: (
        <PartA
          control={control}
          formStatus={formStatus}
          watch={watch}
          neaGeneralOptions={neaGeneralOptions}
          setValue={setValue}
          getValues={getValues}
        />
      ),
      disabled: formStatus !== FormStatusEnum.FORM_NEA_DRAFT,
    }
  }
  //#endregion

  //#region part b
  const NEAPartB = (): FormPartComponents => {
    return {
      partTitle: t('Sample Selection Result'),
      component: <PartB control={control} formStatus={formStatus} getValues={getValues} />,
      disabled: formStatus === FormStatusEnum.FORM_NEA_DRAFT,
    }
  }
  //#endregion

  //#region part c
  const NEAPartC = (): FormPartComponents => {
    return {
      partTitle: t('Audit Assignation'),
      component: (
        <PartC
          control={control}
          formStatus={formStatus}
          getValues={getValues}
          neaGeneralOptions={neaGeneralOptions}
          userInfo={userInfo}
          watch={watch}
        />
      ),
      disabled: formStatus === FormStatusEnum.FORM_NEA_DRAFT,
      additionalBtn: (
        <>
          {['Sys-SA', 'Contract-Admin'].includes(userInfo.defaultRoleName ?? '') && (
            <DialogButton onClick={handleChangeOfficer} sx={{margin: 1}}>
              Update Audit and Checking Officer
            </DialogButton>
          )}
        </>
      ),
    }
  }
  //#endregion

  //#region part d
  const NEAPartD = (): FormPartComponents => {
    return {
      partTitle: t('Submission Confirmation'),
      component: (
        <FormContainer fkey={`${UUID}-nea-d`}>
          <FormSignatureHistories
            formStatusList={globalState.formStatusList}
            histories={getValues('baseForm.formStatusHistories')}
            control={control}
            handleOnComplete={() => handleOnComplete()}
            endOfFlow={formPermission?.endOfFlow}
            userMetaList={globalState.userMetaList}
            roleMetaList={globalState.roleMetaList}
            disabled={!formPermission?.canUpdate}
          />
        </FormContainer>
      ),
    }
  }
  //#endregion

  //#endregion

  const onLoadNotifyList = async (isRejcet: boolean) =>
      await FormService.GetNotifyList('NEA',
                                      getValues('baseForm.formId'),
                                      isRejcet,
                                      getValues('baseForm.districtId'),
                                      getValues('baseForm.teamId'))

  const onLoadHistoryList = async () =>
      await FormService.GetHistoryList(getValues('baseForm.formId'))

  const [FormSwitcher, handleOnComplete] = useFormSwitcher({
                                                             title                    : t('NOTIFICATION OF ENGINEER AUDIT'),
                                                             components               : [NEAPartA(),
                                                                                         NEAPartB(),
                                                                                         NEAPartC(),
                                                                                         NEAPartD()],
                                                             formOnLoadNotifyList     : onLoadNotifyList,
                                                             formOnLoadCommentList    : async () =>
                                                                 await FormService.GetCommentList(getValues('baseForm.formId')),
                                                             formOnSubmitComment      : async (comment) => {
                                                                 await FormService.SubmitComment(getValues('baseForm.formId'), comment)
                                                             },
                                                             formOnLoadFormHistoryList: onLoadHistoryList,
                                                             formOnSubmit             : handleOnSubmit,
                                                             formOnDelete             : handleOnDelete,
                                                             formOnSave               : handleOnSave,
                                                             onExportDocument : handleExportDocument,
                                                             startStep                : step,
                                                             isLoading                : isLoading || isLoadingOption,
                                                             disableSave              : !formPermission?.canUpdate,
                                                             disableDelete            : !formPermission?.canDelete,
                                                             disableComment           : getValues('baseForm.formId') ? false : true,
                                                             approveText              : formStatus === FormStatusEnum.FORM_NEA_REVIEW ? 'Agree' : undefined,
                                                             approveRequired          : formPermission?.workflowRequired,
                                                             endOfFlow                : formPermission?.endOfFlow,
                                                             isMounted                : isMounted.current
                                                           })

  return (
      <>
        <FormSwitcher />
        <DevTool control={ control } />
        <ValidationToast control={ control } />
      </>
  )
}