import { useContext, useEffect, useState }          from 'react'
import { useNavigate }                              from 'react-router-dom'
import { useForm, useWatch }                        from 'react-hook-form'
import { Bar, Line }                                from 'react-chartjs-2'
import { ChartData, ChartDataset }                  from 'chart.js'
import { subDays }                                  from 'date-fns'
import { Box, Stack, styled, useTheme }             from '@mui/system'
import { Button, Card, Grid, Paper, useMediaQuery } from '@mui/material'
import { EaDashboardReq }                           from '@services/model/form/form.EA.model'
import FormEaService                                from '@services/formService/form.EA.service'
import useAPIFetch                                  from '@hooks/useAPIFetch'
import { GlobalContext }                            from '@providers/globalStore'
import FormController                               from '@components/form/controller'
import FormMultipleSelect                           from '@components/form/multipleSelect'
import FormDateTimePicker                           from '@components/form/dateTimePicker'
import FormField                                    from '@components/form/field'
import LoadingPopup                                 from '@components/form/LoadingDialog'
import { formIconProps }                            from '@components/form/overview/icon'
import FormIntroductionBox, { formBriefInfo }       from '@components/form/overview/introductionBox'
import { RightsCategory }                           from '@services/model/form/form.model'
import { getFormColor }                             from '@utils/getFormColor'
import { NavigateTo }                               from '@utils/navigate'
import { colorArray }                               from '@utils/colorList'
import EAInfo                                       from '../../../mockData/fetchData/formInfo/form.EA.json'

const StyledCard = styled(Card)(({ theme }) => ({
    margin : theme.spacing(2),
    padding: theme.spacing(2)
}))

const Overview = ({ isDashboard }: { isDashboard?: boolean }) => {
    const theme                                                  = useTheme()
    const matchUpMd                                              = useMediaQuery(theme.breakpoints.up('md'))
    const { state: globalState, hasRightByCatAndCode, userInfo } = useContext(GlobalContext)

    const navigate                = useNavigate()
    const [formInfo, setFormInfo] = useState(EAInfo)

    const { setRequest, isLoading } = useAPIFetch()

    const [barData, setBarData]   = useState<ChartData<'bar', Array<number>, string>>()
    const [lineData, setLineData] = useState<ChartData<'line', Array<number>, string>>()

    const { control, getValues, setValue } = useForm<EaDashboardReq>({
                                                                         defaultValues: {
                                                                             districtId: [],
                                                                             startDate : subDays(new Date(), 30),
                                                                             endDate   : new Date()
                                                                         }
                                                                     })

    const [districtId, startDate, endDate] = useWatch({
                                                          control: control,
                                                          name   : ['districtId',
                                                                    'startDate',
                                                                    'endDate']
                                                      })

    // form introduction
    const fIconProps: formIconProps = {
        shortName       : formInfo.shortName,
        shortNameBgColor: getFormColor(formInfo.shortName, formInfo.module),
        SvgSx           : { fontSize: '70px' }
    }

    const fInfo: formBriefInfo = {
        id                 : formInfo.id,
        workflowId         : formInfo.workflowId,
        name               : formInfo.name,
        description        : formInfo.description,
        workflowInvolvement: formInfo.workflowInvolvement,
        linkedLists        : formInfo.linkedLists
    }

    const fOnClickSideBtnHandler: Function = () => {
        NavigateTo(navigate, '/engineer-audit')
    }

    const fetchChartData = () => {
        setRequest({
                       callback: async (cancelToken: any) => {
                           FormEaService.GetEaDashboard(getValues(), cancelToken)
                                        .then(async eaDashboard => {
                                                  const uniqueCategory  = [...new Set(eaDashboard.siteDefects.map(item => item.category))]
                                                  const uniqueDefective = [...new Set(eaDashboard.siteDefects.map(item => item.defective))]

                                                  const barDatasets = uniqueDefective.map<ChartDataset<'bar', Array<number>>>(
                                                      (defective, index) => ({
                                                          label          : defective === 'Y' ? 'Defective Site' : 'Site without defects',
                                                          data           : uniqueCategory.map(category => {
                                                              let item = eaDashboard.siteDefects.find(d => d.category === category && d.defective === defective)
                                                              return item ? item.total : 0 // Return total if the item exists, otherwise return 0
                                                          }),
                                                          backgroundColor: colorArray[index] // Get a color for each status
                                                      })
                                                  )
                                                  setBarData({
                                                                 labels  : uniqueCategory,
                                                                 datasets: barDatasets
                                                             })

                                                  setLineData({
                                                                  labels  : eaDashboard.resultIssuingTime.map(d => d.date),
                                                                  datasets: [
                                                                      {
                                                                          label          : 'Number of Days',
                                                                          data           : eaDashboard.resultIssuingTime.map(d => d.averageDays),
                                                                          fill           : true,
                                                                          backgroundColor: 'rgba(11,200,122,0.2)',
                                                                          borderColor    : 'rgba(75,192,192,1)'
                                                                      }
                                                                  ]
                                                              })
                                              }
                                        )
                       }
                   })
    }

    useEffect(() => {
        if (globalState.districtList)
            setValue('districtId', userInfo.districts.filter(
                district => globalState.districtList
                                       ?.map(district => district.key)
                                       .includes(district))
            )

        fetchChartData()
    }, [globalState.districtList])

    useEffect(() => {
        if (districtId.length > 0)
            fetchChartData()
    }, [districtId, startDate, endDate])

    return (
        <Box sx={ {
            padding      : matchUpMd ? '40px' : '10px',
            gap          : '12px',
            display      : 'flex',
            flexDirection: 'column'
        } }>

            <Grid container>
                {
                    !isDashboard &&
                    <Grid item md={ 12 } lg={ 4 }>
                        <StyledCard variant='outlined'>
                            <FormIntroductionBox formIconProps={ fIconProps }
                                                 formBriefInfo={ fInfo }
                                                 hasSideBtn={ true }
                                                 disableSideBtn={ !hasRightByCatAndCode(RightsCategory.FORM_EA, ['C'], true) }
                                                 onClickSideBtnHandler={ fOnClickSideBtnHandler } />
                        </StyledCard>
                    </Grid>
                }

                <Grid item md={ 12 } lg={ isDashboard ? 12 : 8 }>
                    <Grid container sx={ { overflow: 'hidden', width: 'inherit' } }>
                        <FormField fieldName='Districts'
                                   additionalChildren={
                                       <Stack sx={ { flexDirection: matchUpMd ? 'row' : 'column' } }>
                                           <Button sx={ { minWidth: '10%' } }
                                                   onClick={ (event: any) => {
                                                       setValue('districtId',
                                                                globalState.districtList?.map((district) => district.key) ?? [],
                                                                { shouldDirty: true })
                                                   } }>
                                               Select All
                                           </Button>
                                           <Button sx={ { minWidth: '10%' } }
                                                   onClick={ (event: any) => {
                                                       setValue('districtId',
                                                                [],
                                                                { shouldDirty: true })
                                                   } }>
                                               Unselect All
                                           </Button>
                                       </Stack>
                                   }>

                            <FormController controllerProps={ { control, name: 'districtId' } }>
                                <FormMultipleSelect options={
                                    globalState.districtList?.filter(({ key }) =>
                                                                         userInfo.districts.includes(key)) || []
                                } />
                            </FormController>
                        </FormField>

                        <Grid item>
                            <FormField fieldName='Start Date'>
                                <FormController controllerProps={ { control, name: 'startDate' } }>
                                    <FormDateTimePicker type='date' />
                                </FormController>
                            </FormField>
                            <FormField fieldName='End Date'>
                                <FormController controllerProps={ { control, name: 'endDate' } }>
                                    <FormDateTimePicker type='date' />
                                </FormController>
                            </FormField>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <LoadingPopup open={ isLoading } />

            <Paper sx={ { padding: matchUpMd ? '24px' : '8px' } }>
                <Stack sx={ { flexDirection: matchUpMd ? 'row' : 'column' } }>
                    {
                        barData &&
                        <Box width={ matchUpMd ? '50%' : '100%' }>
                            <Bar data={ barData }
                                 options={ {
                                     scales: {
                                         x: { stacked: true },
                                         y: {
                                             stacked: true,
                                             min    : 0,
                                             ticks  : { stepSize: 1 }
                                         }
                                     }
                                 } }
                            />
                        </Box>
                    }

                    {
                        lineData &&
                        <Box width={ matchUpMd ? '50%' : '100%' }>
                            <Line data={ lineData } />
                        </Box>
                    }
                </Stack>
            </Paper>
        </Box>
    )
}

export default Overview